import React, { useState, useEffect } from "react";
import Servicio from "../components/Servicio";
import generateServiciosList from "../informacion/serviciosLista";
import { useNavigate } from "react-router-dom";
import "../styles/servicios.css"
import MovingText from "./MovingText";

function Servicios() {
  const [serviciosLista, setServiciosLista] = useState([]); // This state holds the fetched services
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServicios = async () => {
      const lista = await generateServiciosList(); // Fetch the services from your function
      setServiciosLista(lista); // Update the state with the fetched data
    };

    fetchServicios(); // Call the async function when the component mounts
  }, []);

  return (
    <div>
      {
        //<MovingText /> {/* Uncomment if you want to use MovingText */}
      }
      {serviciosLista.map((servicio) => {
        const { id, title, descripcion, descripcion2, precio, precioPesos, servicioFoto } = servicio;
        return (
          <div key={id}> {/* Always provide a unique key when rendering lists */}
            <Servicio
              id={id}
              title={title}
              fotoServicio={servicioFoto}
            />
          </div>
        );
      })}
    </div>
  );
}

export default Servicios;
