import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { CiEdit } from "react-icons/ci";
import { CiTrash } from "react-icons/ci";

export default function TableExample({ clientes, deleteCliente }) {
  console.log(clientes); // Ensure it's an array and not empty or undefined

  return (
    <div className="outside-table-div">

      <div className="table-div">
        <Table>
          <Thead>
            <Tr className="tabla-rows">
              <Th>Acciones</Th>
              <Th>Demandante Nombre</Th>
              <Th>Demandante Apellido</Th>
              <Th>Demandante DNI</Th>
              <Th>Demandante Domicilio</Th>
              <Th>Demandante Telefono</Th>
              <Th>Demandante Email</Th>
              <Th>Demandante Caratula</Th>
              <Th>Nro de causa</Th>
              <Th>Demandado Nombre</Th>
              <Th>Demandado Apellido</Th>
              <Th>Demandado DNI</Th>
              <Th>Demandado Domicilio</Th>
              <Th>Demandado Telefono</Th>
              <Th>Demandado Email</Th>
              <Th>Demandado Juzgado</Th>
              <Th>Tiene mev?</Th>
              <Th>Tiene convenio?</Th>
              <Th>Causa de</Th>
              <Th>Descripcion</Th>
            </Tr>
          </Thead>
          <Tbody className="tbody-rows">
            {clientes.map((cliente, index) => (

              <Tr key={index}>
                <button className="actions-db"><CiEdit /></button>
                <button className="actions-db"
                  onClick={() => deleteCliente(cliente.numerodecausa)} // Pass the client's code to deleteCliente
                ><CiTrash /></button>
                <Td>{cliente.demandantenombre}</Td>
                <Td>{cliente.demandanteapellido}</Td>
                <Td>{cliente.demandantedni}</Td>
                <Td>{cliente.demandantedomicilio}</Td>
                <Td>{cliente.demandantetelefono}</Td>
                <Td>{cliente.demandanteemail}</Td>
                <Td>{cliente.demandantecaratula}</Td>
                <Td>{cliente.numerodecausa}</Td>
                <Td>{cliente.demandadonombre}</Td>
                <Td>{cliente.demandadoapellido}</Td>
                <Td>{cliente.demandadodni}</Td>
                <Td>{cliente.demandadodomicilio}</Td>
                <Td>{cliente.demandadotelefono}</Td>
                <Td>{cliente.demandadoemail}</Td>
                <Td>{cliente.demandadojuzgado}</Td>
                <Td>{cliente.tieneMev ? "Sí" : "No"}</Td>
                <Td>{cliente.tieneConvenio ? "Sí" : "No"}</Td>
                <Td>{cliente.causade}</Td>
                <Td>{cliente.descripcion}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>

  );
}
