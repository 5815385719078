import React from "react";
import "../styles/vinculados.css";
const Vinculados = () => {
  return (
    <div className="vinculados-section-div">
      <div className="vinculados">
        <h1>Vinculados</h1>
        <h2>
          Un espacio en el que les compartimos nuestros clientes y asociados
        </h2>
        <h3>Punto De Retiro</h3>
        <h3>Picaditas</h3>
        <h3>Fundacion San Ivo</h3>
        <h3>Pizzas City Bell</h3>
      </div>
    </div>
  );
};

export default Vinculados;
