import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import sede1 from "../imagenes/abogado1.jpeg";
import "../styles/servicio.css";

const Servicio = (props) => {
  const { id, title, fotoServicio } = props || {}; // Provide default values to avoid destructuring undefined

  const [isVisible, setIsVisible] = useState(false);
  const servicioRef = useRef(null);
  const navigate = useNavigate();


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(servicioRef.current); // Stop observing after it becomes visible
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1, // Adjust if needed to trigger the animation earlier or later
      }
    );

    if (servicioRef.current) {
      observer.observe(servicioRef.current);
    }

    return () => {
      if (servicioRef.current) {
        observer.unobserve(servicioRef.current);
      }
    };
  }, [servicioRef]);

  if (!id || !title || !fotoServicio) {
    // Handle missing props, maybe render a placeholder or log a warning
    return <div>Loading service...</div>;
  }
    return (

      <div
        ref={servicioRef}
        className={`container-de-todo ${isVisible ? "visible" : ""}`}
      >
        <div
          className="servicio-container"
          key={id}
          onClick={() => navigate("/servicio/" + id)}
        >
          <div className="servicio">
            <div className="imagen-container">
              <img src={fotoServicio} className="imagen-servicio" />
            </div>
            <div className="informacion-container">
              <h1 className="titulo-servicio">{title}</h1>
            </div>
          </div>
        </div>
      </div>

    );


};

export default Servicio;
